import { useSelector } from "react-redux"
import SalesManHome from "../components/SalesManHome"
import WarHouseManHome from "../components/WarHouseManHome"
import SupplierManHome from "../components/SupplierManHome"
import KicthenManHome from "../components/KicthenManHome"
import ManagerHomePage from "../components/ManagerHomePage"


function HomePage(){
    const {
        role
    } = useSelector(store => store.authorization)

    if (role === 1) return <SalesManHome/>
    if (role === 2) return <WarHouseManHome/>
    if (role === 3) return <SupplierManHome/>
    if (role === 4) return <KicthenManHome/>
    if (role === 5) return <ManagerHomePage/>
}


export default HomePage