import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store";
import { createTheme, ThemeProvider } from "@mui/material";

function App() {



  const theme = createTheme({
    typography: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    }
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router}/>
        </PersistGate>
      </ThemeProvider>
    </div>
  );
}
export default App;
