import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getDocumentProductsAction } from "../asyncActions/documentActions"
import { Box, Typography } from "@mui/material"
import DocuemntViewGrid from "../components/DocumentViewGrid"
import DocumentEditGrid from "../components/DocumentEditGrid"


function DocumentPage(){

    const {
        id
    } = useParams()

    const {
        document
    } = useSelector(store => store.documentProducts)

    const {
        role
    } = useSelector(store => store.authorization)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDocumentProductsAction(id))
    }, [])

    const status = {
        1: 'Created',
        2: 'Edited',
        3: 'Delivery',
        4: 'Archived',
    }
    
    return(
        <Box>
            <Box sx={{
                padding: '15px',
                backgroundColor: '#edebeb',
                borderRadius: '15px'
            }}>
                <Typography>Date: {document.detail_date}</Typography>
                <Typography>Author: {document.author?.username}</Typography>
                <Typography>ID: {document.id}</Typography>
                <Typography>Status: {status[document.status]}</Typography>
                {document.isDeclined && <Typography color="red">Declined</Typography>}
                
            </Box>
            {[1,3].includes(role) ? 
                <DocuemntViewGrid/> :
                <DocumentEditGrid/>
            }
            
        </Box>
    )
}

export default DocumentPage