import { createSlice } from '@reduxjs/toolkit'
import productWaste from '../service/wasteProducts.json'


const productWasteSlice = createSlice({
    name: 'productWasteSlice',
    initialState: {
        productWaste
    },
    reducers: {
        addRow(state,action){
            state.rows = [...state.rows, action.payload]
        }, 
        filterRow(state,action){
            state.rows = state.rows.filter(el => el.id !== action.payload)
        }, 
        addRowModesModel(state,action){
            state.rowModesModel = {...state.rowModesModel, [action.payload.id]: action.payload.value}
        }
    },
})

export default productWasteSlice.reducer

export const {
    addRow,
    filterRow,
    addRowModesModel
} = productWasteSlice.actions

