import { Box } from "@mui/material"
import DocuemntGrid from "../components/DocumentGrid"
import DocumentWasteGrid from "../components/DocumentWasteGrid"



function CreateDocumentWastePage(){
    return(
        <Box>
            <DocumentWasteGrid/>
        </Box>
    )
}



export default CreateDocumentWastePage