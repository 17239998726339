import { createSlice } from "@reduxjs/toolkit";


const modalSlice = createSlice({
    name: 'modalSlice',
    initialState: {
        active: false,
        mode: null,
    },
    reducers: {
        setModal(state, action){
            const {
                mode,
                active
            } = action.payload
            state.active = active
            state.mode = mode ?? null
        }
    }
})

export default modalSlice.reducer

export const {
    setModal
} = modalSlice.actions