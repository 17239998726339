import { Box, Button, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import ButtonsSection from "./ButtonsSection";
import StyledModal from "../UI/StyledModal";
import { useCallback, useEffect, useState } from "react";
import { setDeclineDocumentData, setDeclineMode } from "../store/gridDataSlice";


const style = {
    "*": {
        fontWeight: '600',
    },
    "& .edited": {
        backgroundColor: '#f1f1f1',
        color: '#1a3e72',
    },
    "& .local_edit": {
        backgroundColor: '#aeff7e',
        color: '#1a3e72',
    }
}

function DocuemntViewGrid(){


    const dispatch = useDispatch()

    const {
        document,
        documentProducts,
        dataLoad
    } = useSelector(store => store.documentProducts)

    const {
        declineDocumentData,
        declineMode
    } = useSelector(store => store.gridData)

    const defaultColumns = [
        {
            field: 'category_name',
            headerName: 'Category',
            minWidth: 100,
            editable: false,
            flex: 1,
        },
        { field: 'id', headerName: 'ID', width: 40 },
        {
            field: 'name',
            headerName: 'Product name',
            rowSpanValueGetter: () => {},
            minWidth: 250,
            editable: false,
            flex: 1,
        },
        {
            field: 'current_stock',
            headerName: 'Current stock',
            rowSpanValueGetter: () => {},
            type: 'number',
            editable: false,
            minWidth: 80,
            flex: 1,
        },
        {
            field: 'max_stock',
            headerName: 'Max stock',
            rowSpanValueGetter: () => {},
            type: 'number',
            editable: false,
            flex: 1,
            minWidth: 80,
        },
        {
            field: 'needed_qnt',
            headerName: 'Nedded quantity',
            rowSpanValueGetter: () => {},
            type: 'number',
            editable: false,
            flex: 1,
            minWidth: 80,
        },
        {
            field: 'unit_type',
            headerName: 'Unit type',
            rowSpanValueGetter: () => {},
            type: 'number',
            editable: false,
            minWidth: 80,
            flex: 1,
        }
    ]

    const [columns, setCoulmns] = useState(defaultColumns)

    useEffect(() => {
        if (declineMode){
            setCoulmns([...columns, {
                field: 'current_quantity',
                headerName: 'Current Quantity',
                rowSpanValueGetter: () => {},
                type: 'number',
                cellClassName: 'current_quantity',
                editable: true,
                flex: 1,
                minWidth: 80,
            }])
        } else {
            setCoulmns(columns.filter(el => el.field !== 'current_quantity'))
        }
    }, [declineMode])


    const rows = documentProducts.map((elem) => {
        const row = {
            id: elem.product_branch.product.id,
            name: elem.product_branch.product.name,
            category_name: elem.product_branch.product.category?.name,
            unit_type: elem.product_branch.product.unit_type,
            current_stock: +(elem.current_stock),
            max_stock: +(elem.max_stock),
            needed_qnt: +(elem.needed_qnt) 
        }
        if (declineMode){
            let findData = declineDocumentData.find(data => elem.product_branch.product.id === data.id)                            
            row.current_quantity = (findData) ? findData.current_quantity : null
        }
        return row
    })

    const apiRef = useGridApiRef() 


    function onCeildEdit(params){
        setTimeout(() => {
            const { id  } = params;
            let editedRow = new Array(...apiRef.current.getRowModels())
                    .map(el => el[1])
                    .find(elem => elem.id === id)
            dispatch(setDeclineDocumentData(editedRow))
        })
    }


    const handleCellClick = useCallback((params) => {
        try {
          apiRef.current.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        } catch (e) {
        }
      },
      [apiRef],
    );
  
  


    return(
        <Box>
            <Box sx={{display: 'flex', justifyContent: 'center', padding: 2}}>
                <Typography variant="h4">{document.branch?.name}</Typography>
            </Box>
            <Box 
                sx={{...style,minWidth: '300px', height:'60vh'}}
            >
                <DataGrid
                    loading={dataLoad}
                    columns={columns}
                    rows={rows}
                    apiRef={apiRef}
                    onCellClick={handleCellClick}
                    onCellEditStop={onCeildEdit}
                    showCellVerticalBorder
                    // hideFooter
                    unstable_rowSpanning
                />
            </Box>
            <ButtonsSection/>
            <StyledModal/>
        </Box>
    )
}

export default DocuemntViewGrid