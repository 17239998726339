export default function(data) {
    const branchIds = new Set();
    
    data.forEach(item => {
        Object.keys(item).forEach(key => {
            if (key.startsWith('branch_active_')) {
                branchIds.add(key.split('_')[2]);
            }
        });
    });
    
    const updatedData = data.map(item => {
        const newItem = { ...item }; 
        branchIds.forEach(branchId => {
            const activeKey = `branch_active_${branchId}`;
            const bufferKey = `branch_buffer_${branchId}`;
            
            if (!newItem.hasOwnProperty(activeKey)) {
                newItem[activeKey] = null;
            }
            if (!newItem.hasOwnProperty(bufferKey)) {
                newItem[bufferKey] = null;
            }
        });
        return newItem;
    });

    return updatedData;
}
