import { createSlice } from "@reduxjs/toolkit";
import { branchProductsAction } from "../asyncActions/branchActions";


const branchProductsSlice = createSlice({
    name: 'branchProductsSlice',
    initialState: {
        branchProducts: [],
        dataLoad: false,
        error: null
    },
    reducers: {
        setError(state, action){
            state.error = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(branchProductsAction.fulfilled, (state, action) => {
                state.branchProducts = action.payload.sort((a,b) => a.product?.category?.id - b.product?.category?.id)
                state.dataLoad = false
                state.error = null
            })
            .addCase(branchProductsAction.pending, (state) => {
                state.dataLoad = true
                state.error = null
            })
            .addCase(branchProductsAction.rejected, (state, action) => {
                state.dataLoad = false
                state.error = action.payload?.detail && 'some problem'

            })
    }
})



export default branchProductsSlice.reducer

export const {
    setError
} =    branchProductsSlice.actions


// branchProductsAction