import { createSlice } from "@reduxjs/toolkit";
import { getDocumentProductsAction } from "../asyncActions/documentActions";


const documentProductsSLice = createSlice({
    name: 'documentProductsSLice',
    initialState: {
        document: {},
        documentProducts: [],
        dataLoad: false,
        error: null
    },
    reducers: {
        setError(state, action){
            state.error = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDocumentProductsAction.fulfilled, (state, action) => {
                state.document = action.payload.document
                state.documentProducts = action.payload.product_documents.sort((a,b) => a.product_branch.product?.category?.id - b.product_branch.product?.category?.id)
                state.dataLoad = false
                state.error = null
            })
            .addCase(getDocumentProductsAction.pending, (state) => {
                state.dataLoad = true
                state.documentProducts = []
                state.error = null
            })
            .addCase(getDocumentProductsAction.rejected, (state, action) => {
                state.dataLoad = false
                state.error = action.payload?.detail && 'some problem'
            })
    }
})



export default documentProductsSLice.reducer

export const getDocumentProductData = (state) => state.documentProducts

export const {
    setError
} =    documentProductsSLice.actions


// branchProductsAction