import { Box, Typography } from "@mui/material"
import SubmitButton from "../UI/SubmitButton"
import { useDispatch, useSelector } from "react-redux"
import EntryButton from "../UI/EntryButton"
import { setModal } from "../store/modalSlice"
import { useLocation } from "react-router-dom"
import { setDeclineMode } from "../store/gridDataSlice"

import SendIcon from '@mui/icons-material/Send';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

function ButtonSection(){

    
    const {
        document
    } = useSelector(store => store.documentProducts)

    const {
        role
    } = useSelector(store => store.authorization)

    const {
        declineDocumentData,
        declineMode
    } = useSelector(store => store.gridData)

    const dispatch = useDispatch()
    const location = useLocation()

    let status = document.status
    let isManager = (role === 5)


    if ([1,4].includes(role)){

            if(location.pathname.split('/')[3] === 'waste'){

                function handleSubmit(){
                    setTimeout(() => {
                        dispatch(setModal({active: true, mode: '9'}))
                    }, 10)
                }
        
                return (
                    <Box sx={{
                        display: 'flex',
                        padding: '15px 0px',
                        gap: '15px',
                        justifyContent: 'end'
                    }}> 
                        <SubmitButton endIcon={<SendIcon/>} onClick={handleSubmit}>Submit</SubmitButton>
                    </Box>
                )
            }
            
        
            function handleSubmit(){
                if (declineMode){
                    dispatch(setModal({active: true, mode: '12'}))
                } else {
                    dispatch(setModal({active: true, mode: '7'}))
                }
            }
    
            function handleDecline(){
                if (!declineMode){
                    dispatch(setModal({active: true, mode: '11'}))
                }
                dispatch(setDeclineMode())
            }

        return (
            <Box sx={{
                display: 'flex',
                padding: '15px 0px',
                gap: '15px',
                justifyContent: 'end'
            }}>
                {status === 3 ? 
                
                <>
                     <SubmitButton endIcon={declineMode ? <SendIcon/> : <ThumbUpOffAltIcon/>} disabled={document.isDeclined} onClick={handleSubmit}>
                        Approve {declineMode && "Decline"}
                    </SubmitButton>
                     <EntryButton endIcon={declineMode ? <HighlightOffIcon/> : <ThumbDownOffAltIcon/>} disabled={document.isDeclined} onClick={handleDecline}>
                        {declineMode ? 'Cancel' : 'Decline'}
                    </EntryButton>
                </> :
                <Typography>Document was approved</Typography>

                }

            </Box>
        )
    }

    if ([2,5].includes(role)){
        function handleSave(){
            setTimeout(() => {
                dispatch(setModal({active: true, mode: '3'}))
            }, 10)
        }

        function handleSubmit(){
            setTimeout(() => {
                dispatch(setModal({active: true, mode: '5'}))
            }, 10)
        }
        return (
            <Box sx={{
                display: 'flex',
                padding: '15px 0px',
                gap: '15px',
                justifyContent: 'end'
            }}>
                <SubmitButton  endIcon={<BookmarkBorderIcon/>} disabled={status !== 1 || document.isDeclined} onClick={handleSave}>Save</SubmitButton>
                <EntryButton  endIcon={<SendIcon/>} disabled={!(status === 1 || isManager) && document.isDeclined} onClick={handleSubmit}>Submit</EntryButton>
            </Box>  
        )
    }

    
    if (role === 3 ){

        
        function handleSubmit(){
            if (declineMode){
                dispatch(setModal({active: true, mode: '12'}))
            } else {
                dispatch(setModal({active: true, mode: '7'}))
            }
        }

        function handleDecline(){
            if (!declineMode){
                dispatch(setModal({active: true, mode: '11'}))
            }
            dispatch(setDeclineMode())
        }
        return (
            <Box sx={{
                display: 'flex',
                padding: '15px 0px',
                gap: '15px',
                justifyContent: 'end'
            }}>
                {status === 2 ? 
                
                <>
                     <SubmitButton endIcon={declineMode ? <SendIcon/> : <ThumbUpOffAltIcon/>} disabled={document.isDeclined} onClick={handleSubmit}>
                        Approve {declineMode && "Decline"}
                    </SubmitButton>
                     <EntryButton endIcon={declineMode ? <HighlightOffIcon/> : <ThumbDownOffAltIcon/>}  disabled={document.isDeclined} onClick={handleDecline}>
                        {declineMode ? 'Cancel' : 'Decline'}
                    </EntryButton>
                </> :
                <Typography>Document was approved</Typography>

                }

            </Box>
        )
    }
}


export default ButtonSection