import { createBrowserRouter, useLocation } from 'react-router-dom'
import Layout from './Layout'
import AuthPage from './pages/AuthPage'
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { checkTokenAction } from './asyncActions/authActions';
import { logout } from './store/authSlice';
import HomePage from './pages/HomePage';
import CreateDocumentPage from './pages/CreateDocumentPage';
import DocumentPage from './pages/DocumentPage';
import ProductsBufferPage from './pages/ProductsBufferPage';
import CreateDocumentWastePage from './pages/CreateDocumentWastePage';
import DocumentListPage from './pages/DocumentListPage';
import ProductVendorPage from './pages/ProductVendorPage';

const PrivateRoute = ({ Component }) => {
    const dispatch = useDispatch()
    const {
        isAuthenticated
    } = useSelector(store => store.authorization)
    const {pathname} = useLocation()
    useEffect(() => {
        if (!isAuthenticated){
            dispatch(logout())            
        }
        dispatch(checkTokenAction())
    },[isAuthenticated, pathname])
    return isAuthenticated ? <Component/> : <Navigate to="/login" />;
};

const ManagerRoute = ({Component}) => {
    const {
        role
    } = useSelector(store => store.authorization)
    return role === 5 ? <Component/> : <Navigate to='/'/>;
}

const WarhouseRoute = ({Component}) => {
    const {
        role
    } = useSelector(store => store.authorization)
    return role === 2 ? <Component/> : <Navigate to='/'/>;
}

export const router = createBrowserRouter([
    {
      path: '/', 
      element: <Layout/>,
      children: [
          {path: '/', element: <PrivateRoute Component={HomePage}/> },
          {path: '/create/document', element: <PrivateRoute Component={CreateDocumentPage}/> },
          {path: '/create/document/waste', element: <PrivateRoute Component={CreateDocumentWastePage}/> },
          {path: '/admission', element: <WarhouseRoute Component={ProductVendorPage}/> },
          {path: '/document/:id', element: <PrivateRoute Component={DocumentPage}/> },
          {path: '/buffer/products', element: <ManagerRoute Component={ProductsBufferPage}/> },
          {path: '/document/archive', element: <ManagerRoute Component={DocumentListPage}/> },
          {path: '/login', element: <AuthPage/> },
      ]
      },  
  ])