import { Box, CircularProgress, Modal, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import SubmitButton from "./SubmitButton"
import { setModal } from "../store/modalSlice"
import { modalMode } from "../service/modalService"
import EntryButton from "./EntryButton"
import { archiveProductsDocumentAction, createDocuemntAction, createDocuemntWasteAction, deliveryProductsDocumentAction, submitProductsDocumentAction, updateDeclineDocumentProductsAction, updateDocumentProductsAction } from "../asyncActions/documentActions"
import { useNavigate } from "react-router-dom"
import DocumentWasteGrid from "../components/DocumentWasteGrid"
import { createVendorDocumentAction } from "../asyncActions/vendorActions"
import { useEffect } from "react"




function StyledModal({transformData}){
    
    const modal = useSelector(store => store.modal)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        alignItems: 'center'
      };

    if (modal.mode){
        return (
            <Modal
                open={modal.active}
            > 
                <Box sx={style}>
                    <Content 
                        mode={modal.mode} 
                        transformData={transformData}
                    />
                </Box>
            </Modal>
        )
    }
}


function Content({mode, transformData}){

    const content = modalMode[mode]

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const {
        postLoad,
        error
    } = useSelector(store => store.postLoad)

    
    const {
        branchId
    } = useSelector(store => store.branch)

    function closeModal(){
        dispatch(setModal({active: false}))
    }

    const {
        role
    } = useSelector(store => store.authorization)

    const {
        createDocumentWasteData,
        declineDocumentData,
        vendorDocumentData
    } = useSelector(store => store.gridData)


    if (mode === '1'){

        function handleSubmit(){
            dispatch(setModal({active: true, mode: '2'}))
            let data = transformData()

            const converted_data = {
                products: data.products.map(el => ({...el, current_stock: +(el.current_stock).toFixed(2)})),
                branchId: data.branchId

            }
                
            dispatch(createDocuemntAction(converted_data))
        }
    
        return (
            <>
                <Typography>{content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <EntryButton onClick={closeModal}>close</EntryButton>
                    <SubmitButton onClick={handleSubmit}>Accept</SubmitButton>
                </Box>
            </>
        )
    } else if (mode === '2'){

        function closeAndNavigate(){
            closeModal()
            if (!error){
                navigate('/')
            }
        }
        
        return (       
         <>
            {postLoad ? 
                <CircularProgress/>:
                <>
                <Typography>{error || content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <SubmitButton onClick={closeAndNavigate}>confirm</SubmitButton>
                </Box>
                </>
            }
         </>
        )
    } else if (mode === '3'){

        function handleSubmit(){
            dispatch(setModal({active: true, mode: '4'}))
            dispatch(updateDocumentProductsAction())
        }
        return (       
            <>
                <Typography>{content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <EntryButton onClick={closeModal}>close</EntryButton>
                    <SubmitButton onClick={handleSubmit}>Accept</SubmitButton>
                </Box>
            </>
        )
    } else if (mode === '4'){

        function closeAndNavigate(){
            closeModal()
        }
        return (       
         <>
            {postLoad ? 
                <CircularProgress/>:
                <>
                <Typography>{content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <SubmitButton onClick={closeAndNavigate}>confirm</SubmitButton>
                </Box>
                </>
            }
         </>
        )
    } else if (mode === '5'){

        function handleSubmit(){
            dispatch(setModal({active: true, mode: '6'}))
            dispatch(submitProductsDocumentAction())
        }
        return (       
            <>
                <Typography>{content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <EntryButton onClick={closeModal}>close</EntryButton>
                    <SubmitButton onClick={handleSubmit}>Accept</SubmitButton>
                </Box>
            </>
        )
    } else if (mode === '6'){

        function closeAndNavigate(){
            closeModal()
        }
        return (       
         <>
            {postLoad ? 
                <CircularProgress/>:
                <>
                <Typography>{content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <SubmitButton onClick={closeAndNavigate}>confirm</SubmitButton>
                </Box>
                </>
            }
         </>
        )
    } else if (mode === '7'){

        function handleSubmit(){
            dispatch(setModal({active: true, mode: '8'}))
            if ( role === 1){
                dispatch(archiveProductsDocumentAction())
            } else if (role == 3){
                dispatch(deliveryProductsDocumentAction())
            }
        }
        return (       
            <>
                <Typography>{content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <EntryButton onClick={closeModal}>close</EntryButton>
                    <SubmitButton onClick={handleSubmit}>Accept</SubmitButton>
                </Box>
            </>
        )
    } else if (mode === '8'){

        function closeAndNavigate(){
            closeModal()
        }
        return (       
         <>
            {postLoad ? 
                <CircularProgress/>:
                <>
                <Typography>{content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <SubmitButton onClick={closeAndNavigate}>confirm</SubmitButton>
                </Box>
                </>
            }
         </>
        )
    } else if (mode === '9'){

        function handleSubmit(){
            dispatch(setModal({active: true, mode: '10'}))
            let body = {
                products: createDocumentWasteData.map(el => ({
                    product: el.productName,
                    category: el.category,
                    quantity: el.quantity
                })),
                branch_id: branchId
            }
            console.log(body);
            
            dispatch(createDocuemntWasteAction(body))

        }
        return (       
            <>
                <Typography>{content.text}</Typography>
                <Typography>Total WASTED products: {createDocumentWasteData.length}</Typography>
                
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <EntryButton onClick={closeModal}>close</EntryButton>
                    <SubmitButton onClick={handleSubmit}>Accept</SubmitButton>
                </Box>
            </>
        ) 
    }  else if (mode === '10'){

        function closeAndNavigate(){
            closeModal()
            navigate('/')
        }
        
        return (       
            <>
            {postLoad ? 
                <CircularProgress/>:
                <>
                <Typography>{content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <SubmitButton onClick={closeAndNavigate}>confirm</SubmitButton>
                </Box>
                </>
            }
         </>
        ) 
    } else if (mode === '11'){

        function closeAndNavigate(){
            closeModal()
        }
        return (       
         <>
            {postLoad ? 
                <CircularProgress/>:
                <>
                <Typography>{content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <SubmitButton onClick={closeAndNavigate}>confirm</SubmitButton>
                </Box>
                </>
            }
         </>
        )
    } else if (mode === '12'){

        function handleSubmit(){
            dispatch(setModal({active: true, mode: '13'}))
            dispatch(updateDeclineDocumentProductsAction())
        }

    
        return (       
            <>
                <Typography>{content.text}</Typography>
                <Typography>Total DECLINE products: {declineDocumentData.length}</Typography>
                
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <EntryButton onClick={closeModal}>close</EntryButton>
                    <SubmitButton onClick={handleSubmit}>Accept</SubmitButton>
                </Box>
            </>
        ) 
    }  else if (mode === '13'){

        function closeAndNavigate(){
            closeModal()
            navigate('/')
        }
        
        return (       
            <>
            {postLoad ? 
                <CircularProgress/>:
                <>
                <Typography>{content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <SubmitButton onClick={closeAndNavigate}>confirm</SubmitButton>
                </Box>
                </>
            }
         </>
        ) 
    } else if (mode === '14'){

        function handleSubmit(){
            dispatch(setModal({active: true, mode: '15'}))
            dispatch(createVendorDocumentAction(vendorDocumentData))
        }
    
        return (       
            <>
                <Typography>{content.text}</Typography>
                <ul>
                    {vendorDocumentData.map(elem => (
                        <li>{elem.name}: <span style={{color: 'orange'}}>{elem.quantity}</span></li> 
                    ))}
                </ul>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <EntryButton onClick={closeModal}>close</EntryButton>
                    <SubmitButton onClick={handleSubmit}>Accept</SubmitButton>
                </Box>
            </>
        ) 
    }  else if (mode === '15'){

        function closeAndNavigate(){
            closeModal()
            if (!error){
                navigate('/')
            }
        }
        
        return (       
            <>
            {postLoad ? 
                <CircularProgress/>:
                <>
                <Typography>{error || content.text}</Typography>
                <Box sx={{display:'flex', gap: '10px', justifyContent: 'space-between' }}>
                    <SubmitButton onClick={closeAndNavigate}>confirm</SubmitButton>
                </Box>
                </>
            }
         </>
        ) 
    } else {
        <h1>loh</h1>
    }

}


export default StyledModal