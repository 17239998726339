import { createAsyncThunk } from "@reduxjs/toolkit"
import { vendorProductUnit } from "../units/supplyGetUnits"
import { createVendorDocumentUnit } from "../units/supplyPostUnits"



export const vendorProductsAction = createAsyncThunk('vendor/products', async(_, {dispatch, rejectWithValue}) => {
    try {
        const response = await vendorProductUnit()
        return response
    } catch (e){
        return rejectWithValue(e)
    }
})



export const createVendorDocumentAction = createAsyncThunk('create/vendordocument', async(data, {dispatch, rejectWithValue}) => {
    try {
        const body = data.map(el => ({product: el.id, quantity: el.quantity, price: el.price}))
        const response = await createVendorDocumentUnit(body)
        return response
    } catch (e){
        return rejectWithValue(e)
    }
})


