import { createAsyncThunk } from "@reduxjs/toolkit"
import { bufferProductsUnit } from "../units/supplyGetUnits"
import { updateProductBranch } from "../units/supplyPutUnits"
import { getBufferData } from "../store/bufferSlice"


export const getBufferProductsAction = createAsyncThunk('buffer/products', async(params, {dispatch, rejectWithValue}) => {
    try {
        const response = await bufferProductsUnit(params)
        return response
    } catch (e){
        return rejectWithValue(e)
    }
})


export const updateProductBranchAction = createAsyncThunk('update/productbranch', async(data, {dispatch, rejectWithValue, getState}) => {
    try {
        const state = getState()
        const {selectedCategory, selectedTypeBranch} = getBufferData(state)
        const response = await updateProductBranch(data)
        const params =  {
            category_id: selectedCategory,
            branch_type: selectedTypeBranch
        } 
        dispatch(getBufferProductsAction(params))
        return response
    } catch (e){
        return rejectWithValue(e)
    }
})

