import { Button, styled } from "@mui/material";



const StyledButton = styled(Button)({
    backgroundColor: 'rgb(186 56 74)',
    color: 'white'
})




function EntryButton({children, ...props}){
    return(
        <StyledButton {...props}>
            {children}
        </StyledButton>
    )
}

export default EntryButton