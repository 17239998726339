import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkTokenUnit, loginUnit } from "../units/authUnits";
import { logout } from "../store/authSlice";



export const loginAction = createAsyncThunk('auth/login', async (credentials, {rejectWithValue}) => {
    try{
        const response = await loginUnit(credentials)
        return response
    } catch(e) {        
        return rejectWithValue(e.response.data)
    }
})

export const checkTokenAction = createAsyncThunk('auth/check', async (_,{dispatch}) => {
        try{
            await checkTokenUnit()
        } catch (e){            
            dispatch(logout())
        }
})


