import { createSlice } from "@reduxjs/toolkit";
import { createDocuemntAction, createDocuemntWasteAction, deliveryProductsDocumentAction, submitProductsDocumentAction, updateDocumentProductsAction } from "../asyncActions/documentActions";
import { createVendorDocumentAction } from "../asyncActions/vendorActions";


const postLoadSlice = createSlice({
    name: 'postLoadSlice',
    initialState: {
        postLoad: false,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(createDocuemntAction.pending, (state) => {
                state.postLoad = true
                state.error = null
            })
            .addCase(createDocuemntAction.fulfilled, (state) => {
                state.postLoad = false
            })
            .addCase(createDocuemntAction.rejected, (state, action) => {
                state.error = action.payload.message
                state.postLoad = false
            })
            .addCase(updateDocumentProductsAction.pending, (state) => {
                state.postLoad = true
            })
            .addCase(updateDocumentProductsAction.fulfilled, (state) => {
                state.postLoad = false
            })
            .addCase(updateDocumentProductsAction.rejected, (state) => {
                state.postLoad = false
            })
            .addCase(submitProductsDocumentAction.pending, (state) => {
                state.postLoad = true
            })
            .addCase(submitProductsDocumentAction.fulfilled, (state) => {
                state.postLoad = false
            })
            .addCase(submitProductsDocumentAction.rejected, (state) => {
                state.postLoad = false
            })
            .addCase(deliveryProductsDocumentAction.pending, (state) => {
                state.postLoad = true
            })
            .addCase(deliveryProductsDocumentAction.fulfilled, (state) => {
                state.postLoad = false
            })
            .addCase(deliveryProductsDocumentAction.rejected, (state) => {
                state.postLoad = false
            })     
            .addCase(createDocuemntWasteAction.pending, (state) => {
                state.postLoad = true
            })
            .addCase(createDocuemntWasteAction.fulfilled, (state) => {
                state.postLoad = false
            })
            .addCase(createDocuemntWasteAction.rejected, (state) => {
                state.postLoad = false
            })
            .addCase(createVendorDocumentAction.pending, (state) => {
                state.postLoad = true
                state.error = null
            })
            .addCase(createVendorDocumentAction.fulfilled, (state) => {
                state.postLoad = false
                state.error = null
            })
            .addCase(createVendorDocumentAction.rejected, (state,action) => {
                state.error = action.payload.message
                state.postLoad = false
            })

    }
})

export default postLoadSlice.reducer

export const {
    
} = postLoadSlice.actions

