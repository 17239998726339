import { Box, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { branchProductsAction, kitchenProductsAction } from "../asyncActions/branchActions";
import SubmitButton from "../UI/SubmitButton";
import { setModal } from "../store/modalSlice";
import StyledModal from "../UI/StyledModal";
import { setError } from "../store/branchProductsSlice";
import { setCreateDocument } from "../store/gridDataSlice";

import SendIcon from '@mui/icons-material/Send';




function DocuemntGrid(){

    const dispatch = useDispatch()

    const {
        role
    } = useSelector(store => store.authorization)


    const {
        branchProducts,
        error,
        dataLoad
    } = useSelector(store => store.branchProducts)

    const {
        branchName,
        branchId
    } = useSelector(store => store.branch)

    const {
        createDocumentData
    } = useSelector(store => store.gridData)

    useEffect(() => {
        if (role === 4){
            dispatch(kitchenProductsAction(branchId))        
        } else {
            dispatch(branchProductsAction())  
        }
    },[])
    

    const apiRef = useGridApiRef() 
    const gridRef = useRef() 


    const rows = branchProducts.map((elem) => {
        let findData = createDocumentData.find(data => data.product_branch === elem.id)
    
        return {
            id: elem.product.id,
            name: elem.product.name,
            category_name: elem.product.category?.name,
            unit_type: elem.product.unit_type,
            current_stock: (findData) ? findData.current_stock : null
            
        }
    })

    const columns = [
        {
            field: 'category_name',
            headerName: 'Category',
            width: 100,
            cellClassName: 'sticky-column',
            editable: false,
        },
        { field: 'id', headerName: 'ID', width: 40 },
        {
            field: 'name',
            headerName: 'Product name',
            width: 250,
            rowSpanValueGetter: () => {},
            editable: false,
        },
        {
            field: 'current_stock',
            headerName: 'Current stock',
            type: 'number',
            width: 130,
            rowSpanValueGetter: () => {},
            editable: true,
        },
        {
            field: 'unit_type',
            headerName: 'Unit type',
            rowSpanValueGetter: () => {},
            width: 130,
            editable: false,
        }
    ]



    const handleSubmit = () => {
        let gridProducts = new Array(...apiRef.current.getRowModels()).map(el => el[1])
        if (gridProducts.some(elem => typeof(elem.current_stock) !== 'number')){
            dispatch(setError('Validate Error. You miss current stock at row'))
         } else {
            dispatch(setError(null))
            dispatch(setModal({active: true, mode: '1'}))
         }  
    }

    const handleCellClick = useCallback((params) => {
        try {
          apiRef.current.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        } catch {}
      },
      [apiRef],
    );

    function transformData(){
        let gridProducts = new Array(...apiRef.current.getRowModels()).map(el => el[1])
        // console.log(gridProducts);
        
        let transformData = branchProducts.map((product) => {
            const editedProduct =  gridProducts.find((elem) => elem.id === product.product.id)
            return {
                product_branch:  product.id,
                current_stock:  editedProduct.current_stock,
            }
        })
        // console.log(transformData);
        
        return {products: transformData, branchId}
    }


    const onCeilEdit = () => {
        setTimeout(() => {
            dispatch(setCreateDocument(transformData().products))
        })
    }
    


    return(
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3
        }}>
            <Box sx={{display: 'flex', justifyContent: 'center', padding: 2}}>
                <Typography variant="h4">{branchName}</Typography>
            </Box>
            <Box>
                {error && <Typography variant="h6" color="red">{error}</Typography>}
            </Box>
            <Box 
                sx={{minWidth: '300px', height:'60vh'}}
                ref={gridRef}
            >
                <DataGrid
                    loading={dataLoad}
                    columns={columns}
                    rows={rows}
                    apiRef={apiRef}
                    ref={gridRef}
                    onCellEditStop={onCeilEdit}
                    unstable_rowSpanning
                    showCellVerticalBorder
                    pageSize={200}
                    pinnedColumns={{ left: ['category_name'] }} // Закрепляем столбец "Category"
                    onCellClick={handleCellClick}
                />
            </Box>
            <Box sx={{
                alignSelf: 'flex-end'
            }}>
                <SubmitButton endIcon={<SendIcon/>} onClick={handleSubmit}>Submit</SubmitButton>
            </Box>
            <StyledModal transformData={transformData}/> 
        </Box>
    )
}




export default DocuemntGrid