import { Button, styled } from "@mui/material";



const StyledButton = styled(Button)({
    backgroundColor:'#f89e28',
    color: 'balck',
    fontWeight: 600,
    '--variant-textColor': 'black',
    padding: '10px 60px',
    borderRadius: '16px'
})




function SubmitButton({children, ...props}){
    return(
        <StyledButton {...props} sx={{color: '#950303'}}>
            {children}
        </StyledButton>
    )
}

export default SubmitButton