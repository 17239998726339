import { instance } from "./interceptors"


export const createDocumentUnit = async (data) => {
    let body = {
        products: data.products,
        branch_id: data.branchId
    }
    const response = await instance.post('/api/create/document/', body)
    return response.data
}   


export const createDocumentWasteUnit = async (data) => {
    const response = await instance.post('/api/create/document/waste', data)
    return response.data
}   


export const updateProductsDocumentUnit = async(data) => {
    let {
        document_id,
        products
    } = data
    const response = await instance.put('/api/document/update/'+document_id, products)
    return response
}


export const createVendorDocumentUnit = async(data) => {
    const response = await instance.post('/api/create/vendordocument/', data)
    return response
}

