import { AppBar, Box, Button, Container, Toolbar, Typography } from '@mui/material';
import ringoImage from '../assets/ringo_logo.png'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EntryButton from '../UI/EntryButton';
import { logout } from '../store/authSlice';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';


function Header(){

    const {
        isAuthenticated,
        username,
        role
    } = useSelector(store => store.authorization)

    const dispatch = useDispatch()

    return(
        <Box  sx={{flexGrow: 1, backgroundColor:'#f89e28'}}>
            <AppBar color='#f89e28' position='static'>
                <Container maxWidth='xl' sx={{padding: 1}}>
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box sx={{width: '100%',display: 'flex', gap: 2}}>
                            { isAuthenticated && 
                                <Link to='/'>
                                    <Typography variant='h5'>
                                        Home
                                    </Typography>
                                </Link>       
                            }
                            {role === 5 && 
                                <>
                                    <Link to='/buffer/products'>
                                        <Typography variant='h5'>
                                            Bufer manager
                                        </Typography>
                                    </Link>   

                                    <Link to='/document/archive'>
                                        <Typography variant='h5'>
                                            Archive
                                        </Typography>
                                    </Link>   
                                </>
                            }
                            {role === 2 && 
                                <>
                                    <Link to='/admission'>
                                        <Typography variant='h5'>
                                            Admission
                                        </Typography>
                                    </Link>     
                                </>
                            }                         
                        </Box>
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <img  sx={{flexGrow: 1}} width={100} src={ringoImage}/>
                        </Box>
                        <Box sx={{width:'100%',display: 'flex', gap: 2, justifyContent: 'end'}}>
                            {isAuthenticated ? 
                                <>
                                    <Typography variant='h5'>{username}</Typography>
                                    <EntryButton endIcon={<LogoutIcon/>} onClick={() => dispatch(logout())}>Log-out</EntryButton>
                                </> : 
                                <Link to={'/login'}>
                                    <EntryButton endIcon={<LoginIcon/>} variant='contained' color='error'>Login</EntryButton>
                                </Link>
                            }
                        </Box>  
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    )
}



export default Header