import { Box, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBufferProductsAction, updateProductBranchAction } from "../asyncActions/productsActions";
import { setCategory, setTypeBranch } from "../store/bufferSlice";
import addAllBranch from "../service/addAllBranch";
import SubmitButton from "../UI/SubmitButton";


function ProductsBufferPage() {

    const dispatch = useDispatch()

    const [error, setError] = useState(null)
    const defaultColumns = [
        {
            field: 'id',
            headerName: 'id',
            maxWidth: 50,
            flex: 1
        },
        {
            field: 'product',
            headerName: 'Product name',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'unit_type',
            headerName: 'Unit type',
            maxWidth: 80,
            flex: 1
        }
    ];
    
    const {
        branchList,
        bufferList,
        dataLoad,
        dataUpdate,
        categoryList,
        selectedTypeBranch,
        selectedCategory
    } = useSelector(store => store.buffer)
    const [columns, setColumns] = useState(defaultColumns)

    useEffect(() => {
        const params = {
            branch_type: selectedTypeBranch,
            category_id: selectedCategory
        }
        dispatch(getBufferProductsAction(params))
    }, [selectedCategory, selectedTypeBranch])

    const rows = addAllBranch(bufferList)
    
    const branhColumns = branchList.map(el => {
        const buffer_column = {
            field: 'branch_buffer_' + el.branch_id,
            headerName: 'Max stock',
            minWidth: 80,
            type: 'number',
            editable: true,
            flex: 1,
        }
        const second_column = {
            field: 'branch_active_' + el.branch_id,
            headerName: 'Active',
            minWidth: 80,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        { params.row[`branch_buffer_${el.branch_id}`] !== null && 
                            <Checkbox 
                                checked={params.row[`branch_active_${el.branch_id}`]}
                                onClick={(e) => {
                                    const data = {
                                        product: +params.id,
                                        branch: +params.field.split('_')[2],
                                        is_active: e.target.checked,
                                    }
                                    dispatch(updateProductBranchAction(data))
                                }}
                            />
                        }
                    </>
                )
            }
        }

        return [buffer_column, second_column]
    }).map(el => el.flat()).flat()

    useEffect(() => {
        setColumns([...defaultColumns, ...branhColumns])
    }, [branchList])


    const columnGroupingModel = [
        {
            groupId: 'Products',
            children: [{ field: 'id' }, { field: 'product' }, { field: 'unit_type' }],
        },
        ...branchList.map(branch => ({
            groupId: branch.branch_name,
            children: [
                { field: 'branch_buffer_' + branch.branch_id },
                { field: 'branch_active_' + branch.branch_id },
            ]
        }))
    ];

    const apiRef = useGridApiRef()

    async function onCeilEdit(param){
        setTimeout(() => {
            let row = apiRef.current.getRowWithUpdatedValues(param.id)
            const max_stock = row[param.field]
            const data = {
                product: +row.id,
                branch: +param.field.split('_')[2],
                max_stock
            }

            if (typeof max_stock === 'number'){
                setError(null)
                dispatch(updateProductBranchAction(data)) 
            } else {
                setError('Enter valid value (only numeric)')
            }
          
            
        }, 100)        
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 4,
                padding: '15px 0px'
            }}>
                <Typography variant="h3">Buffer manager</Typography>
                {error && <Typography sx={{color: 'red'}}>{error}</Typography>}
                <Box  component='form' sx={{
                    display: 'flex',
                    gap: 3
                }}>
                    <FormControl sx={{width: '250px'}}>
                            <InputLabel id="branch_type">Branch Type</InputLabel>
                            <Select
                                defaultValue={selectedTypeBranch}
                                disabled={dataLoad}
                                labelId="branch_type"
                                label="Branch Type"
                                onChange={(e) => dispatch(setTypeBranch(e.target.value))}
                                required
                            >
                                <MenuItem value={1}>Kitchen</MenuItem>
                                <MenuItem value={2}>Branch</MenuItem>
                            </Select>
                    </FormControl>
                    <FormControl sx={{width: '250px'}}>
                            <InputLabel id="category">Category</InputLabel>
                            <Select
                                defaultValue={selectedCategory}
                                disabled={dataLoad}
                                ref={apiRef}
                                labelId="category"
                                label="Category"
                                onChange={(e) => dispatch(setCategory(e.target.value))}
                                required
                            >
                                {categoryList.map((category) => 
                                    <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                )}
                            </Select>
                    </FormControl>
                </Box>
            </Box>
            
            <Box  sx={{minWidth: '300px', height:'60vh'}}>
                <DataGrid
                    loading={dataLoad || dataUpdate}
                    columns={columns}
                    apiRef={apiRef}
                    rows={rows}
                    columnGroupingModel={columnGroupingModel}
                    showCellVerticalBorder
                    hideFooter
                    onCellEditStop={onCeilEdit}
                />
            </Box>
        </Box>
    )
}

export default ProductsBufferPage