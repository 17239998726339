import { Box, Typography } from "@mui/material"
import DocumentList from "./DocumentList"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getDocumentListAction } from "../asyncActions/documentActions"


function SupplierManHome(){

    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getDocumentListAction())
    }, [])

    const {
        documents
    } = useSelector(store => store.branch)

    const filteredDocuments = documents.filter(doc => !(doc.branch?.isKitchen))

    return (
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            gap: 10,
            width: '100%'
        }}>
            <Box sx={{
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                gap: 3,
                border: '1px solid #f1f1f1',
                borderRadius: '16px',
                padding: '25px'
            }}>
                <Typography variant="h4">Delivery Documents</Typography>
                <DocumentList documents={filteredDocuments}/>
            </Box>            
        </Box>
    )
}

export default SupplierManHome