import { Box, Button, Typography } from "@mui/material"
import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import { addRow, addRowModesModel, filterRow } from "../store/productWasteSlice";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { updateCreateWasteData } from "../store/gridDataSlice";
import clsx from "clsx";
import ButtonSection from "./ButtonsSection";
import StyledModal from "../UI/StyledModal";

function DocumentWasteGrid(){
    
const style = {
    "*": {
        fontWeight: '600',
    }
}


    const {
        branchName
    } = useSelector(store => store.branch)

    const {
        productWaste
    } = useSelector(store => store.productWaste)

    const {
        createDocumentWasteData
    } = useSelector(store => store.gridData)

    const dispatch = useDispatch()


    const apiRef = useGridApiRef() 

    const rows = productWaste.map(product => {
        let findData = createDocumentWasteData.find(data => data.id === product.id)
        return {
            id: product.id,
            category: product.category.name,
            productName: product.product_name,
            unitType: product.unit_type,
            quantity: (findData) ? findData.quantity : 0,
        }
    })


    const columns = [
        {
            field: 'category',
            headerName: 'Category',
            editable: false,
            width: 150
        },
        {
            field: 'productName',
            headerName: 'Product',
            editable: false,
            rowSpanValueGetter: () => {},
            width: 250
        },
        {
            field: 'unitType',
            headerName: 'Unit Type',
            editable: false,
            rowSpanValueGetter: () => {},
            width: 100
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            rowSpanValueGetter: () => {},
            editable: true,
            width: 150
        }
    ]
    
    

    function onCeildEdit(params){
        setTimeout(() => {
            const { id  } = params;
            let editedRow = new Array(...apiRef.current.getRowModels())
                    .map(el => el[1])
                    .find(elem => elem.id === id)
            dispatch(updateCreateWasteData(editedRow))
        })
    }


    return(
       <Box >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2}}>
                <Typography variant="h4">{branchName}</Typography>
                <Typography variant="h5">Edit wasted products</Typography>
            </Box>
            <Box sx={{...style,minWidth: '300px', height:'75vh'}}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    apiRef={apiRef}
                    onCellEditStop={onCeildEdit}
                    // hideFooter
                    unstable_rowSpanning
                    showCellVerticalBorder
                />
            </Box>
            <ButtonSection/>
            <StyledModal/>
       </Box>
    )
}


export default DocumentWasteGrid

