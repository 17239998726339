import { Box } from "@mui/material"
import DocuemntGrid from "../components/DocumentGrid"



function CreateDocumentPage(){
    return(
        <Box>
            <DocuemntGrid/>
        </Box>
    )
}



export default CreateDocumentPage