import { Box, Typography } from "@mui/material";
import SubmitButton from "../UI/SubmitButton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FileOpenIcon from '@mui/icons-material/FileOpen';



function DocumentItem({document}){

    const {
        status
    } = document

    const {
        role
    } = useSelector(store => store.authorization)

    // {
    //     1: [ROLE] {
    //         1: [STATUS]
    //     }
    // }


    const styleBorderStatus = {
        1: {
            1: {
                boxShadow: '0px 5px 15px 0px rgba(98,255,76,1)',
            },
            2: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            3: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            4: {
                boxShadow: '0px 5px 15px 0px rgba(98,255,76,1)',
            },
        },
        2: {
            1: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            2: {
                boxShadow: '0px 5px 15px 0px  rgba(98,255,76,1)',
            },
            3: {
                boxShadow: '0px 5px 15px 0px  rgba(98,255,76,1)',
            },
            4: {
                boxShadow: '0px 5px 15px 0px  rgba(98,255,76,1)',
            },
        },
        3: {
            2: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            3: {
                boxShadow: '0px 5px 15px 0px  rgba(98,255,76,1)',
            },
        },
        4: {
            1: {
                boxShadow: '0px 5px 15px 0px rgba(98,255,76,1)',
            },
            2: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            3: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            4: {
                boxShadow: '0px 5px 15px 0px rgba(98,255,76,1)',
            },
        }
    }

    let declinedStyle = {
        boxShadow: '0px 5px 15px 0px rgb(255 2 2)',
    }

    return (
        <Box               
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                height: '170px',
                gap: 3,
                borderRadius: '16px',
                boxShadow:  (document.isDeclined) ? declinedStyle.boxShadow : styleBorderStatus[role]?.[status]?.boxShadow,
                border: '0.5px solid #f1f1f1',
            }}
        key={document.id}>
            <Typography variant="h6">{document.detail_date}</Typography>
            <Typography>{document.branch?.name}</Typography>
            <Link to={'/document/'+document.id}>
                <SubmitButton endIcon={<FileOpenIcon/>}>Open</SubmitButton>                        
            </Link>
        </Box>
    )
}


export default DocumentItem