import { instance } from "./interceptors"



export const updateProductsDocumentUnit = async(data) => {
    let {
        document_id,
        products
    } = data
    const response = await instance.put('/api/document/update/'+document_id, products)
    return response
}

export const updateDeclineProductsDocumentUnit = async(data) => {
    let {
        document_id,
        products
    } = data
    const response = await instance.put('/api/document/decline/update/'+document_id, products)
    return response
}


export const submitProductsDocumentUnit = async (id) => {
    const response = await instance.put('/api/document/submit/'+id)
    return response
}


export const deliveryProductsDocumentUnit = async (id) => {
    const response = await instance.put('/api/document/delivery/'+id)
    return response
}

export const archiveProductsDocumentUnit = async (id) => {
    const response = await instance.put('/api/document/archive/'+id)
    return response
}


export const updateProductBranch = async (data) => {
    const response = await instance.put('/api/update/productbranch/', data)
    return response
}
