import { createAsyncThunk } from "@reduxjs/toolkit";
import { createDocumentUnit, createDocumentWasteUnit, updateProductsDocumentUnit } from "../units/supplyPostUnits";
import { documentListUnit, documentProductsUnit, documentView } from "../units/supplyGetUnits";
import { clearData, getGridData, setCreateDocument, setDeclineMode, setUpdateDocument } from "../store/gridDataSlice";
import { getDocumentProductData } from "../store/documentProductsSlice";
import { archiveProductsDocumentUnit, deliveryProductsDocumentUnit, submitProductsDocumentUnit, updateDeclineProductsDocumentUnit } from "../units/supplyPutUnits";
import dayjs from "dayjs";



export const createDocuemntAction = createAsyncThunk('create/document', async(data, {dispatch, rejectWithValue}) => {
    try {        
        const response = await createDocumentUnit(data)
        dispatch(setCreateDocument([]))
        return response
    } catch (e){
        return rejectWithValue(e)
    }
})


export const createDocuemntWasteAction = createAsyncThunk('create/document/waste', async(data, {dispatch, rejectWithValue}) => {
    try {
        const response = await createDocumentWasteUnit(data)
        dispatch(clearData('createDocumentWasteData'))
        return response
    } catch (e){
        return rejectWithValue(e)
    }
})

export const getDocumentProductsAction = createAsyncThunk('document/products', async (id,{rejectWithValue}) => {
    try {
        const response = await documentProductsUnit(id)
        return response
    } catch(e){
        return rejectWithValue(e)
    }
}) 


export const getDocumentListAction = createAsyncThunk('/document/list', async (_,{rejectWithValue}) => {
    try {
        const response = await documentListUnit()
        return response
    } catch(e) {
        return rejectWithValue(e)
    }
})


export const updateDocumentProductsAction = createAsyncThunk('/update/documents/products', async (_, {rejectWithValue, dispatch, getState}) => {
    try {
        const state = getState()
        const gridDataSlice = getGridData(state)
        const productDocumentSlice = getDocumentProductData(state)

        let productsDocumentId = productDocumentSlice.documentProducts.map(el => el.id)
        let editedProductsDocument = gridDataSlice.updateDocumentData.filter(elem => productsDocumentId.includes(elem.product_document_id) && elem.localEdited)
        let deletedProductsDocumentData = gridDataSlice.updateDocumentData.filter( el => !(productsDocumentId.includes(el.product_document_id)))

        let data = {
            products: editedProductsDocument.map((elem => ({
                product_document_id: elem.product_document_id,
                needed_qnt: elem.needed_qnt
            }))),
            document_id: productDocumentSlice.document.id
        }

        const response = await updateProductsDocumentUnit(data)


        dispatch(setUpdateDocument(deletedProductsDocumentData))
        dispatch(getDocumentProductsAction(data.document_id))
        return response
    } catch(e) {
        return rejectWithValue(e)
    }
})


export const updateDeclineDocumentProductsAction = createAsyncThunk('/update/decline/documents/products', async (_, {rejectWithValue, dispatch, getState}) => {
    try {
        const state = getState()
        const gridDataSlice = getGridData(state)
        const productDocumentSlice = getDocumentProductData(state)
        

        let editedProductsDocument = gridDataSlice.declineDocumentData
                                                            .filter(el => el.current_quantity)
                                                            .map(el => ({
                                                                product_document_id: productDocumentSlice.documentProducts.find(pr => pr.product_branch.product.id === el.id).id,
                                                                current_quantity: el.current_quantity,
                                                            }))

        let data = {
            products: editedProductsDocument,
            document_id: productDocumentSlice.document.id
        }

 
        
        const response = await updateDeclineProductsDocumentUnit(data)


        dispatch(setDeclineMode())
        dispatch(clearData('declineDocumentData'))
        dispatch(getDocumentProductsAction(data.document_id))
        return response
    } catch(e) {        
        return rejectWithValue(e)
    }
})


export const submitProductsDocumentAction = createAsyncThunk('/document/submit', async(_, {rejectWithValue, getState, dispatch}) => {
    try {
        const state = getState()
        const productDocumentSlice = getDocumentProductData(state)
        let document_id = productDocumentSlice.document.id

        dispatch(updateDocumentProductsAction())
        const response = await submitProductsDocumentUnit(document_id)
        dispatch(getDocumentProductsAction(document_id))
        dispatch(clearData('declineDocumentData'))
        return response
    } catch(e) {
        return rejectWithValue(e)
    }
})

export const deliveryProductsDocumentAction = createAsyncThunk('/document/delivery', async(_, {rejectWithValue, getState, dispatch}) => {
    try {
        const state = getState()
        const productDocumentSlice = getDocumentProductData(state)
        const response = await deliveryProductsDocumentUnit(productDocumentSlice.document.id)
        dispatch(getDocumentProductsAction(productDocumentSlice.document.id))
        return response
    } catch(e) {
        return rejectWithValue(e)
    }
})

export const archiveProductsDocumentAction = createAsyncThunk('/document/archive', async(_, {rejectWithValue, getState, dispatch}) => {
    try {
        const state = getState()
        const productDocumentSlice = getDocumentProductData(state)
        const response = await archiveProductsDocumentUnit(productDocumentSlice.document.id)
        dispatch(getDocumentProductsAction(productDocumentSlice.document.id))
        return response
    } catch(e) {
        return rejectWithValue(e)
    }
})



export const doucmentViewAction = createAsyncThunk('/document/archive', async(date, {rejectWithValue, getState, dispatch}) => {
    try {
        const formatedDate = date.format('YYYY-MM-DD')
        const response = await documentView(formatedDate)
        return response
    } catch(e) {
        return rejectWithValue(e)
    }
})
