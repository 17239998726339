

export const modalMode = {
    '1': {
        text: 'Did u want to save document?',
        btnText: 'Submit'
    },
    '2': {
        text: 'Document was created!',
        btnText: 'Submit'
    },
    '3': {
        text: 'Did u want to SAVE document?',
        btnText: 'Submit'
    },
    '4': {
        text: 'Document was saved!',
        btnText: 'Submit'
    },    
    '5': {
        text: 'Did u want to SUBMIT document?',
        btnText: 'Submit'
    },
    '6': {
        text: 'Document was submited!',
        btnText: 'Submit'
    },
    '7': {
        text: 'Did u want to APPROVE document?',
        btnText: 'Submit'
    },
    '8': {
        text: 'Document was approved!',
        btnText: 'Submit'
    },    
    '9': {
        text: 'Did you want to send WASTE products?',
        btnText: 'Submit'
    }, 
    '10': {
        text: 'Document was submited!',
        btnText: 'Submit'
    }, 
    '11': {
        text: 'Decline mode activated',
        btnText: 'Submit'
    }, 
    '12': {
        text: 'Did u want to decline document?',
        btnText: 'Submit'
    }, 
    '13': {
        text: 'Document was declined!',
        btnText: 'Submit'
    }, 
    '14': {
        text: 'Aprrove product list:',
        btnText: 'Submit'
    }, 
    '15': {
        text: 'Admission was added!',
        btnText: 'Submit'
    }, 
}
