import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBranchAction } from "../asyncActions/branchActions"
import { Box, Typography } from "@mui/material"
import SubmitButton from "../UI/SubmitButton"
import { Link } from "react-router-dom"
import DocumentList from "./DocumentList"
import { getDocumentListAction } from "../asyncActions/documentActions"



function WarHouseManHome(){

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDocumentListAction())
    }, [])

    const {
        branchName,
        branchAddress,
        documents
    } = useSelector(store => store.branch)
    

    const [kitchenDocuments, branchDocuments] = [
        documents.filter(el => el.branch.isKitchen),
        documents.filter(el => !(el.branch.isKitchen)),
    ]


    return(
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: 5,
            width: '100%'
        }}>
            {/* <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3
            }}>
                <Typography variant="h4">Enter products to warehouse</Typography>
                <Link to={'/warehouse/new'}>
                    <SubmitButton>Mutki products</SubmitButton>
                </Link>
            </Box> */}
            <Box sx={{
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                gap: 3,
                border: '1px solid #bebebe',
                borderRadius: '16px',
                padding: '25px',
                width: '100%'

            }}>
                <Typography variant="h4">Branch documents</Typography>
                {branchDocuments.length === 0 ? 
                    <Typography>No branch documents yet</Typography>
                    :
                    <DocumentList documents={branchDocuments}/>
                }
            </Box>            
            <Box sx={{
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                gap: 3,
                border: '1px solid #bebebe',
                borderRadius: '16px',
                padding: '25px',
                width: '100%'
            }}>
                <Typography variant="h4">Kitchen documents</Typography>
                {kitchenDocuments.length === 0 ? 
                    <Typography>No kitchen documents yet</Typography>
                    :
                    <DocumentList documents={kitchenDocuments}/>
                }
            </Box>     
        </Box>
    )
}


export default WarHouseManHome