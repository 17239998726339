
import { createSlice } from "@reduxjs/toolkit";
import { vendorProductsAction } from "../asyncActions/vendorActions";


function transformData(data){
    let vendors = []
    let vendorProducts = []
    for(let elem of data){
        if (!vendors.some(el => el.id === elem.vendor.id)){
            vendors.push(elem.vendor)
        }
        elem.vendor = elem.vendor.id
        vendorProducts.push(elem)
    }
    return {
        vendors,
        vendorProducts
    }
}


const vendorSlice = createSlice({
    name: 'vendorSlice',
    initialState: {
        selectFilter: {
            vendor: "",
            product: ''
        },
        vendorList: [],
        productList: [],
        productVendorList: [],
        dataLoad: false,
    },
    reducers: {
        clearFilterVendor(state){
            state.selectFilter = {
                vendor: null,
                product: null
            }
        },
        setSelectFilter(state, action){
            const {filter, value} = action.payload
            state.selectFilter[filter] = value
        },
        setProductVendor(state,action){
            const vendorId = action.payload
            state.productVendorList = state.productList.filter(product => product.vendor === vendorId)
        }
    }, 
    extraReducers: (builder) => {
        builder 
            .addCase(vendorProductsAction.fulfilled, (state, action) => {
                const {vendors, vendorProducts} = transformData(action.payload)
                state.vendorList = vendors
                state.productList = vendorProducts
                state.dataLoad = false
            })
            .addCase(vendorProductsAction.rejected, (state, action) => {
                state.dataLoad = false
            })
            .addCase(vendorProductsAction.pending, (state, action) => {
                state.dataLoad = true
            })

    }
})



export default vendorSlice.reducer

export const getBufferData = (state) => state.vendor


export const {
    clearFilterVendor,
    setSelectFilter,
    setProductVendor
} = vendorSlice.actions
