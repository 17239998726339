import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SubmitButton from "../UI/SubmitButton";
import { Link } from "react-router-dom";
import DocumentItem from "./DocumentItem";






function DocumentList({documents}){

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: 5,
            }}>
                {documents.map(document => 
                        <DocumentItem key={document.id} document={document}/>
                )}

            {documents.length === 0 && <Typography>Empty list</Typography>}
            </Box>
        </Box>
    )
}


export default DocumentList