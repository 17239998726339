import { Box, CircularProgress, FormControl, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { loginAction } from "../asyncActions/authActions"
import { useNavigate } from "react-router-dom"
import EntryButton from "../UI/EntryButton"
import LoginIcon from '@mui/icons-material/Login';




function AuthPage(){

    const [login, setLogin] = useState(null)     
    const [password, setPassword] = useState(null)   

    const navigate = useNavigate()

    const {
        error,
        isAuthenticated,
        authLoad
    } = useSelector(store => store.authorization)
    
    const dispatch = useDispatch()

    function submitHandle(e){
        e.preventDefault()
        const creds = {
            username: login,
            password
        }
        dispatch(loginAction(creds))
    }

    useEffect(() => {
        if (isAuthenticated  && !authLoad){
               setTimeout(() => {
                    navigate('/')
               },100)
        }
    }, [isAuthenticated])

    return(
        <Box sx={{
            margin: 5,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 3
        }}>
            <Typography variant="h2">Sign in</Typography>
            <Box component='form' onSubmit={submitHandle} sx={{p: 2,display: 'flex', alignItems: 'center',flexDirection: 'column', gap: 2, width: '400px'}}>

                    <TextField
                        disabled={authLoad}
                        required
                        fullWidth
                        name="login"
                        label="Login"
                        type="login"
                        onChange={(e) => setLogin(e.target.value)}
                    />                   


                    <TextField
                        disabled={authLoad}
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />

                {error && <p style={{color: 'red'}}>{error}</p>}
                {
                    authLoad ?
                    <CircularProgress/> : 
                    <EntryButton endIcon={<LoginIcon/>} type='submit' onClick={submitHandle}>sign in</EntryButton>
                }
            </Box>
        </Box>
    )
}



export default AuthPage